import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  updateListingDescriptionLanguage(event) {
    let selectedLang = event.target.dataset.lang;
    let selectedLangName = event.target.dataset.langName;
    let selectedDescription = document.getElementById('description-translation-' + selectedLang);
    let descriptionsOptions = document.querySelectorAll('.description-translation-option');
    let descriptions = document.querySelectorAll('.description-translation');
    let descriptionTranslationToggle = document.getElementById('description-translation-toggle');
    
    
    [].forEach.call(descriptionsOptions, function(description) {
      description.classList.remove('selected');
    });
       
    [].forEach.call(descriptions, function(description) {
      description.classList.add('d-none');
    });
    
    event.target.classList.add('selected');
    selectedDescription.classList.remove('d-none');
    descriptionTranslationToggle.innerHTML = selectedLangName;
  }
}
