import { Controller } from '@hotwired/stimulus'
import etjs from 'etjs'
import ahoy from 'ahoy.js'

export default class extends Controller {

  initialize() {
    this.phoneHome();
    if (this.etValues().includeAhoy) {
      this.configureAhoy();
    }
  }

  trackVisit() {
    var page_view_url = this.data.get('pageViewUrl');
    if(page_view_url) {
      Rails.ajax({
        type: 'post',
        url: page_view_url
      });
    }

  }

  etValues() {
    var etElement = document.getElementById('et');
    return JSON.parse(etElement.dataset.analyticsInfo);
  }

  configureAhoy() {
    var etElementValues = this.etValues();
    ahoy.configure({headers: {'Account-Token': etElementValues.memberToken}});
  }

  phoneHome() {
    var etjsObj = this.createPageOptionsObj();
    etjs.turbolinksInit(etjsObj);
  }

  createPageOptionsObj() {
    var that = this;
    var etElementValues = this.etValues();
    var etjsObj = {
      gaCode: 'UA-1965509-2',
      gaV4Code: 'G-RRXL4BBBM4',
      isProduction: etElementValues.isProduction,
      fireWithTrackPage: function () {
        if (that.etValues().includeAhoy) {
          ahoy.trackView();
        }
        that.trackVisit();
      },
      fireWithTrackEvent: function () {
        // TODO add ahoy event traking  ahoy.track("My event", {language: "JavaScript"});
      },
      debugger: !etElementValues.isProduction,
      pageOpts: {
        listingID: etElementValues.listingID,
        listingPostalCode: etElementValues.listingPostalCode,
        listingCountry: etElementValues.listingCountry,
        listingStateProvince: etElementValues.listingStateProvince,
        listingCity: etElementValues.listingCity,
        memberToken: etElementValues.memberToken
      }
    };
    return etjsObj;
  }

  trackPage() {
    var etjsObj = this.createPageOptionsObj();
    etjs.trackPage(etjsObj);
  }

  trackEvent(event) {
    if (typeof event == 'string') {
      var eventObj = {
        'name': event,
        'action': 'send'
      }
    } else {
      var eventObj = event;
    }
    etjs.trackEvent(eventObj);
  }

}
