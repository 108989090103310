import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  reportOutbound(event) {
    var linkUrl = event.srcElement.href;
    var linkText = event.srcElement.textContent;
    var url = "/outbound_link.js?referrer=" + encodeURIComponent(window.location.href) + "&url=" + encodeURIComponent(linkUrl) + '&text=' + encodeURIComponent(linkText);

    Rails.ajax({
      type: 'post',
      url: url
    });
  }

  reportFakeOutbound(url, text) {
    var url = "/outbound_link.js?referrer=" + encodeURIComponent(window.location.href) + "&url=" + encodeURIComponent(url) + '&text=' + encodeURIComponent(text);
    Rails.ajax({
      type: 'post',
      url: url
    });
  }

  visitLink(event) {
    var url = event.srcElement.dataset.url;
    var text = event.srcElement.textContent;
    this.reportFakeOutbound(url, text);
    var redirectWindow = window.open(url, '_blank');
    redirectWindow.location;
  }

}
