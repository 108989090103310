import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  initialize() {
    jQuery('*:not(.bootstrap-select) > .selectpicker').selectpicker({style: 'btn-theme btn-sm'});
    jQuery( function() {
      fixSelectpickerAriaLabels();
    });
  }

}

function fixSelectpickerAriaLabels() {
  $('.selectpicker').each(function() {
    var button = $('button[data-id="'+this.id+'"]').first();
    if(button.length > 0) {
      button.attr('aria-label', $(this).attr('aria-label'));
    }
  });
}
