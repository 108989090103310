import { Controller } from '@hotwired/stimulus'
import * as noUiSlider from 'nouislider'

var price_slider;

export default class extends Controller {

  static targets = ['listingCard']

  listingCardTargetConnected(card) {
    if (card.dataset.lhKey) {
      var lhKey = JSON.parse(card.dataset.lhKey);
      this.analyticsController.trackSearchResults(lhKey);
    }
  }

  getSearchQuery() {
    var searchParams = [];
    var form = document.getElementById('search-parameters');
    var inputs = form.getElementsByTagName('input');
    var $selects = jQuery('select.selectpicker:not(#saved_search_email_frequency)');
    var areaInput = document.getElementById('area');
    var areaValue = '';
    var countryValue = '';
    var subdivisionValue = '';

    // Get select values
    jQuery.each($selects, function(index, select){
      var selectedOption = jQuery(select).find('option:selected').val();
      if (selectedOption != ''){
        if (jQuery(select).hasClass('location')) {
          if (jQuery(select).hasClass('subdivisions')) {
            subdivisionValue = selectedOption;
          } else {
            countryValue = selectedOption;
          }
        } else {
          searchParams.push(jQuery(select).attr('name') + '=' + selectedOption);
        }
      }
    });

    // Set area input value
    if (subdivisionValue != '' || countryValue != '') {
      areaInput.value = subdivisionValue != '' ? subdivisionValue : countryValue;
    }

    [].forEach.call(inputs, function(input) {

      if ( !input.classList.contains('skip') ){
        if (input.type == 'checkbox') {
          if (input.checked == true) {
            searchParams.push(input.name + '=' + input.value);
          }
        } else if (input.value != '') {
          searchParams.push(input.name + '=' + input.value);
        }
      }
    });

    var query = searchParams.join('&');
    return query;

  }

  paginate(event) {
    event.preventDefault();
    this.getResults(this.data.get('url'));
    this.trackSearchResultsPageviews();
  }

  trackSearchResultsPageviews() {
    this.analyticsController.trackPage();
  }

  submit(event) {

    if (event) {
      event.preventDefault();
    }

    var that = this;
    var predictiveSearch = document.querySelector('#predictive-search .suggestions li.selectedLi a.selected, #predictive-search .suggestions li.initial a');

    if (predictiveSearch) {
      this.visit(predictiveSearch.href);
    } else {
      setTimeout(function() {
        var query = that.getSearchQuery();
        var baseURL = location.pathname;
        var url = baseURL + '?' + query;
        that.getResults(url);
      }, 100);
    }
    closeSuggestions('#q', '#predictive-search');

  }

  visit(url) {
    window.location.href = url;
  }

  getResults(url) {
    var that = this;
    Rails.ajax({
      type: 'get',
      url: url,
      success: function(response) {
        that.updateResults(response);
        that.updateURL(url);
      },
      error: function(response) {
        console.log('ajax error: ' + response);
      }
    });
  }

  updateResults(results) {
    if (location.pathname.includes('map')) {
      luxestrap.mapkitHelpers.updateMapResults(results);
    } else {
      var el = document.getElementById('results');
      el.innerHTML = results;
    }
  }

  displayAllListings() {
    document.getElementById('flash-results').classList.add('d-none');
    document.getElementById('profileId').value = '';
    document.getElementById('developmentId').value = '';
    document.getElementById('profileName').value = '';

    // Submit form with reset values
    var that = this;
    setTimeout(function() {
      that.getResults(location.pathname);
    }, 100);
  }

  toggleGallery() {
    var mapOptions = document.querySelectorAll('.map-link');
    var galleries = document.querySelectorAll('.gallery-option');
    [].forEach.call(mapOptions, function(mapOption) {
      mapOption.classList.remove('active');
    });
    [].forEach.call(galleries, function(gallery) {
      gallery.classList.add('active');
    });
    var params = this.getSearchQuery();
    var subpath = window.location.pathname.replace('/map', '');
    var url = subpath + '?' + params;
    window.location.href = url;

  }

  toggleMap(e) {
    e.preventDefault();
    var mapOptions = document.querySelectorAll('.map-link');
    if (!mapOptions[0].classList.contains('disabled')) {
      var galleries = document.querySelectorAll('.gallery-option');
      [].forEach.call(galleries, function(gallery) {
        gallery.classList.remove('active');
      });
      [].forEach.call(mapOptions, function(mapOption) {
        mapOption.classList.add('active');
      });
      var params = this.getSearchQuery();
      var subpath = window.location.pathname.replace('/map', '');
      var url = subpath + '/map?' + params;
      window.location.href = url;
    }
  }

  updateURL(url) {
    var searchForm = document.getElementById('search-parameters');
    var setupController = this.application.getControllerForElementAndIdentifier(searchForm, 'setup');
    history.replaceState(null, '', url);
    window.onpopstate = function(event) {
      Turbo.visit(document.location)
    }
  }

  resetSubdivisions() {
    document.getElementById('area').value = '';
    jQuery('.selectpicker.subdivisions').selectpicker('val', '').addClass('d-none');
  }

  compileCheckboxGroupParam(event) {
    var input = event.target;
    var hiddenInput = document.getElementById(this.data.get('param'));
    var setValue = hiddenInput.value.split(',');
    var newValue = [];

    openCloseCheckboxGroup(input);

    var selectedValue = input.value;

    var selectedFields = document.querySelectorAll('input.active.' + this.data.get('param'));

    if (input.classList.contains('active') && !input.checked) {
      setValue = setValue.filter(function(el) {
        return el != selectedValue;
      });

    }  else {
      setValue.push(selectedValue);

      if (input.dataset.parent) {
        setValue = setValue.filter(function(el) {
          return el != input.dataset.parent;
        });
      }
    }

    [].forEach.call(selectedFields, function(field) {
      newValue.push(field.value);
    });

    // Sanitize and set values
    hiddenInput.value = newValue.filter(stripDuplicateAndEmptyValues).join();

    // Submit form
    this.submit();

  }

  reset() {

    var isMapSearch = location.pathname.includes('map');
    var searchForm = document.getElementById('search-parameters');

    // Reset range sliders
    var sliders = searchForm.getElementsByClassName('range-slider');
    if (sliders) {
      [].forEach.call(sliders, function(slider) {
        slider.noUiSlider.set([slider.noUiSlider.options.range.min, slider.noUiSlider.options.range.max]);
      });
    }

    // Reset input values
    var inputs = searchForm.getElementsByTagName('input');
    if (inputs) {

      [].forEach.call(inputs, function(input) {
        if (!isMapSearch && !input.classList.contains('location') && input.type != 'checkbox') {
          input.value = '';
        }
      });
    }
    jQuery('#flash-results').alert('close');
    document.querySelector('#predictive-search ul').innerHTML = '';

    // Reset checkboxes & checkbox groups
    var checkboxes = searchForm.querySelectorAll('input[type=checkbox]');
    var checkboxGroups = searchForm.querySelectorAll('.checkbox-group');
    if (checkboxes) {
      [].forEach.call(checkboxes, function(checkbox) {
        checkbox.checked = false;
        checkbox.classList.remove('active');
      });
    }
    if (checkboxGroups) {
      [].forEach.call(checkboxGroups, function(group) {
        group.classList.remove('open');
        group.classList.add('closed');
      });
    }

    // Reset selects
    if (isMapSearch) {
      jQuery('.selectpicker:not(.location)').selectpicker('val', '');
    } else {
      jQuery('.selectpicker').selectpicker('val', '');
    }

    // Submit form with reset values
    var that = this;
    setTimeout(function() {
      that.getResults(location.pathname);
    }, 300);
  }

  geoLocationSearch() {
    var searchQuery = document.getElementById('q').value;

    if( navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, {enableHighAccuracy: true, timeout : 5000});
    }

    // geo search functions
    function geoSuccess(position) {
      if (position.coords.latitude == 0 && position.coords.longitude == 0) {
        alert("Unable to get location");
      } else {
        var geocenter = position.coords.latitude + "," + position.coords.longitude;
        var searchURL = document.getElementById('home-search-type').value;
        var query = document.getElementById("q").value;
        window.location.href = searchURL + "?geocenter=" + geocenter + "&distance=100&sort=distance&q=" + query;
      }
    }

    function geoError(error) {
      if (error.code == 1) {
        alert("User denied access to location");
      } else {
        alert("Unable to get location");
      }
    }
  }

  updateListingSliders() {
    document.getElementById('price_min').value = null;
    document.getElementById('price_max').value = null;
    var url = this.searchURL();
    this.submit();
    document.getElementById("listing_search_sliders").setAttribute("src", url);
  }

  updateListingKind() {
    this.updateListingSliders();
    this.resetIdealForBoxes();
    var url = this.searchURL();
    document.getElementById("listing_search_form").setAttribute("src", url);
    document.getElementById("listing_search_subdivisions").setAttribute("src", url);
  }

  resetIdealForBoxes() {
    var checkboxes = document.querySelectorAll('input.feature');
    [].forEach.call(checkboxes, function(box) {
      box.checked = false;
    });
  }

  searchURL(options) {
    var query = this.getSearchQuery();
    var baseURL = location.pathname;
    var url = baseURL + '?' + query;
    return url;
  }

  toggleSidebarButtonState(event) {
    if (event) {
      event.preventDefault();
    }
    document.getElementById('search-filter-button').classList.toggle('btn-outline-secondary');
    document.getElementById('search-filter-button').classList.toggle('btn-secondary');
  }

  hideSubdivisions() {
    var subdivisions = document.querySelectorAll('.subdivisions');
    [].forEach.call(subdivisions, function(subdivision) {
      subdivision.classList.add('d-none');
      subdivision.value = '';
    });
  }

  showSubdivisions(event) {

    var areaValue = jQuery(event.srcElement).val();

    if (areaValue && areaValue.length > 2) {
      switch (areaValue.substring(0,2)) {
        case 'AU':
          this.hideSubdivisions();
          jQuery('.country').val('AU');
          jQuery('.country').selectpicker('val', 'AU');
          jQuery('.AU-subdivisions').val(areaValue);
          jQuery('.AU-subdivisions').removeClass('d-none');
          break;
        case 'CA':
          this.hideSubdivisions();
          jQuery('.country').val('CA');
          jQuery('.country').selectpicker('val', 'CA');
          jQuery('.CA-subdivisions').val(areaValue);
          jQuery('.CA-subdivisions').removeClass('d-none');
          break;
        case 'US':
          this.hideSubdivisions();
          jQuery('.country').val('US');
          jQuery('.country').selectpicker('val', 'US');
          jQuery('.US-subdivisions').removeClass('d-none');
          jQuery('.US-subdivisions').val(areaValue);
      }
    } else {
      switch (areaValue) {
        case 'AU':
          this.hideSubdivisions();
          jQuery('.country').val('AU');
          jQuery('.country').selectpicker('val', 'AU');
          jQuery('.AU-subdivisions').removeClass('d-none');
          break;
        case 'CA':
          this.hideSubdivisions();
          jQuery('.country').val('CA');
          jQuery('.country').selectpicker('val', 'CA');
          jQuery('.CA-subdivisions').removeClass('d-none');
          break;
        case 'US':
          this.hideSubdivisions();
          jQuery('.country').val('US');
          jQuery('.country').selectpicker('val', 'US');
          jQuery('.US-subdivisions').removeClass('d-none');
          break;
        default:
          this.hideSubdivisions();
          jQuery('.country').val(areaValue);
          jQuery('.country').selectpicker('val', areaValue);
      }
    }
  }

  getMapBounds() {
    var auSelect = document.getElementById('AU-subdivisions');
    var caSelect = document.getElementById('CA-subdivisions');
    var usSelect = document.getElementById('US-subdivisions');
    var $selectedCountry = false;

    if (auSelect && jQuery('.AU-subdivisions option:selected').val().length > 0) {
      $selectedCountry = jQuery('.AU-subdivisions option:selected');
    } else if (caSelect && jQuery('.CA-subdivisions option:selected').val().length > 0) {
      $selectedCountry = jQuery('.CA-subdivisions option:selected');
    } else if (usSelect && jQuery('.US-subdivisions option:selected').val().length > 0) {
      $selectedCountry = jQuery('.US-subdivisions option:selected');
    } else if (jQuery('.country option:selected').val().length > 0) {
      $selectedCountry = jQuery('.country option:selected');
    }

    if ($selectedCountry && $selectedCountry[0].hasAttribute('data-ne-lat') && $selectedCountry[0].hasAttribute('data-ne-lng') && $selectedCountry[0].hasAttribute('data-sw-lat') && $selectedCountry[0].hasAttribute('data-sw-lng')) {
      var boundingRegion = new mapkit.BoundingRegion($selectedCountry.data('ne-lat'), $selectedCountry.data('ne-lng'), $selectedCountry.data('sw-lat'), $selectedCountry.data('sw-lng'));
      var region = boundingRegion.toCoordinateRegion();
      return boundingRegion
    } else {
      return false
    }
  }

  setMapBounds() {

    var mapBounds = this.getMapBounds();

    if (mapBounds) {
      var region = mapBounds.toCoordinateRegion();

      var map = mapkit.maps[0];
      if (map) {
        map.setRegionAnimated (region, true);
      }
    }
  }

  enableMapLink() {
    var searchQuery = location.search;
    var disabled = this.getMapBounds() ? false : true;

    jQuery('.map-link').each(function(mapLink) {
      if (disabled) {
        jQuery(this).addClass('disabled');
        jQuery(this).prop('disabled', true);
        jQuery('.tooltip-wrapper').tooltip('enable');
      } else {
        jQuery('.tooltip-wrapper').tooltip('disable');
        jQuery(this).removeClass('disabled');
        jQuery(this).prop('disabled', false);
      }
    });
  }

  get analyticsController() {
    var etjsElement = document.getElementById('et');
    return this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics')
  }

}

function removeParamsFromQueryString(params, queryString) {

  var searchParams = new URLSearchParams(queryString);

  if (Array.isArray(params)) {
    params.forEach(function(param){
      if (searchParams.has(param)) {
        searchParams.delete(param);
      }
    });
    return searchParams.toString();
  } else {
    if (searchParams.has(params)) {
      return searchParams.delete(params);
    }
  }

}

function formatCurrency(number) {
  var decPlaces = 0; //isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  var decSep = '.'; // typeof decSep === "undefined" ? "." : decSep;
  var thouSep = ','; //typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? '-' : '';
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign + '$' + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

// Unrender suggestions
function closeSuggestions(input, suggestionsObj) {
  jQuery(input).removeData();
  document.querySelector(suggestionsObj +' ul').innerHTML = '';
}

function stripDuplicateAndEmptyValues(value, index, self) {
  return value != '' && self.indexOf(value) === index;
}

function openCloseCheckboxGroup(checkbox) {
  var dupes = document.querySelectorAll('input[value="' + checkbox.value + '"]:not([type="hidden"])');

  [].forEach.call(dupes, function(input) {
    var inputGroup = input.parentElement;
    var parent = document.getElementById(input.dataset.parent);
    var activeChildren = inputGroup.querySelectorAll('input.active[type="checkbox"]');

    if (input.classList.contains('active') || inputGroup.classList.contains('open')) {

      inputGroup.style.maxHeight = '2500px';

      input.classList.remove('active');
      inputGroup.classList.remove('open');
      inputGroup.classList.add('closed');
      input.checked = false;

      var activeSiblings = document.querySelectorAll('.checkbox-group.open[data-parent="' + input.dataset.parent + '"] input.active');
      var openSiblings = document.querySelectorAll('.checkbox-group.open[data-parent="' + input.dataset.parent + '"]');

      if (parent && input == checkbox && activeSiblings.length < 1) {
        parent.classList.remove('open');
        inputGroup.classList.add('closed');
        parent.classList.add('active');

      } else {
        if (parent && input != checkbox) {
          if (activeSiblings.length == 0 && openSiblings.length == 0) {
            var falseSelections = checkbox.parentElement.parentElement.querySelectorAll('.checkbox-group.open');
            [].forEach.call(falseSelections, function(selection) {
              selection.classList.remove('open');
              selection.classList.add('closed');
              var childrenInputs = selection.querySelectorAll('input');
              [].forEach.call(childrenInputs, function(child) {
                child.checked = false;
              });
            });
          }
        }
      }

      if (activeChildren) {
        [].forEach.call(activeChildren, function(child) {
          child.classList.remove('active');
          child.checked = false;
          child.parentElement.classList.remove('open');
          child.parentElement.classList.add('closed');
        });
      }

      setTimeout(function() {
        inputGroup.style.maxHeight = null;
      }, 400);

    } else {

      input.classList.add('active');
      inputGroup.classList.add('open');
      inputGroup.classList.remove('closed');
      input.checked = true;

      if (parent) {
        parent.classList.remove('active');
      }

    }

  }); // forEach END
}
