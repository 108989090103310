import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['auto', 'light', 'dark'];

  initialize() {
    this.activateTheme(this.theme)
  }

  activateTheme(theme) {
    // Add class to body
    var body = document.body;
    body.classList.remove('dark', 'light', 'auto');
    body.classList.add(this.theme);

    // Update selected theme in menu
    document.querySelectorAll('.theme-switcher .theme-link').forEach(node => {
      if (node.classList.contains(this.theme)) {
        node.classList.add('selected');
      } else {
        node.classList.remove('selected');
      }
    });

  }

  updateTheme() {
    this.theme = this.data.get('selected');
    this.activateTheme(this.theme);
    this.broadcastThemeChange();
  }

  broadcastThemeChange() {
    const event = document.createEvent('Event');
    event.initEvent('themeChange', true, true);
    document.dispatchEvent(event);
  }

  set theme(value) {
    window.localStorage.setItem('theme', value);
  }

  get theme() {
    var savedTheme = window.localStorage.theme;
    var selectedTheme = savedTheme == 'dark' || savedTheme == 'light' || savedTheme == 'auto' ? savedTheme : 'auto';
    return selectedTheme;
  }
}
