import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = { marker: Array }

  connect() {
    this.initMapkit();

    if (this.scope.element.classList.contains('search')) {
      this.loadSearchMap();
    } else if (this.scope.element.classList.contains('directory')) {
      this.loadDirectoryMap();
    } else if (this.scope.element.classList.contains('regions')) {

    } else {
      this.loadListingMap();
    }
  }

  disconnect() {
    if(mapkit.maps.length > 0) {
      mapkit.maps[0].destroy();
    }
  }

  initMapkit() {
    mapkit.init({ authorizationCallback: function(done) { fetch("/mapkit_token")
        .then(res => res.text())
        .then(token => done(token)) /* If successful, return your token to MapKit JS */
        .catch(error => { /* Handle error */ });
    }});
  }

  loadListingMap() {
    var MarkerAnnotation = mapkit.MarkerAnnotation, clickAnnotation;
    var coords = new mapkit.Coordinate(parseFloat(this.markerValue[0]), parseFloat(this.markerValue[1]));

    var listingMarker = new MarkerAnnotation(coords, {
      color: 'rgb(74, 82, 90)',
      glyphImage: {
        1: '/images/doorian-gray.png',
        2: '/images/doorian-gray@2x.png',
        3: '/images/doorian-gray@3x.png'
      }
    });

    var map = new mapkit.Map('map', {
      center: coords
    });

    map.addAnnotation(listingMarker);

    map.setCenterAnimated(coords);
    setTimeout(function() {
      var span = new mapkit.CoordinateSpan(0.016, 0.016),
          region = new mapkit.CoordinateRegion(coords, span);
      map.setRegionAnimated(region);
    }, 800);


    this.tidyUp();

  }


  loadSearchMap() {
    var region;
    var mapBounds;
    var selectedSubdivision = false;

    var mapBoundsInput = document.getElementById('map_bounds');
    var locationSelects = document.querySelectorAll('select.subdivisions option:checked');
    [].forEach.call(locationSelects, (select) => {
      if (select.value != '') {
        selectedSubdivision = select
      }
    });


    if (mapBoundsInput.value) {
      mapBounds = mapBoundsInput.value;
    } else {

      var locationSelects = document.querySelectorAll('select.subdivisions option:checked');
      [].forEach.call(locationSelects, (select) => {
        if (select.value != '') {
          selectedSubdivision = select
        }
      });

      var selectedCountry = document.querySelector('select.country option:checked');
      var selection;
      var neLat = false;
      var neLng = false;
      var swLat = false;
      var swLng = false;

      if (selectedSubdivision && selectedSubdivision.dataset.hasOwnProperty('neLat' && 'neLng' && 'swLat' && 'swLng')) {
        selection = selectedSubdivision;
      } else if (selectedCountry) {
        selection = selectedCountry;
      } else {
        selection = false;
      }

      if (selection) {
        neLat = parseFloat(selection.dataset.neLat);
        neLng = parseFloat(selection.dataset.neLng)
        swLat = parseFloat(selection.dataset.swLat);
        swLng = parseFloat(selection.dataset.swLng);
        region = new mapkit.BoundingRegion(neLat, neLng, swLat, swLng).toCoordinateRegion();
      } else {
        region = '';
      }

    }

    if (mapkit.maps.length === 0) {
      var map = new mapkit.Map('map', {
        region: region
      });
    } else {
      var map = mapkit.maps[0];
      map.setRegionAnimated (region, true);
    }

    var completeGeocodes = [];
    if (this.hasMarkerValue) {
      this.markerValue.forEach((marker) => {

        if (marker['geocode'] != null && selection) {
          var lat = parseFloat(marker['geocode']['lat']);
          var lng = parseFloat(marker['geocode']['lng']);
          var inLatRange = neLat > lat && swLat < lat;
          var inLngRange = neLng > lng && swLng < lng;
          if (inLatRange && inLngRange) {
            completeGeocodes.push(marker);
          }
        }

      });
    }

    // Add markers to the map
    luxestrap.mapkitHelpers.updateMapResults(completeGeocodes, '/');

  }

  loadDirectoryMap() {
    var center = new mapkit.Coordinate(40, -60);
    var padding = new mapkit.Padding({top: 5, right: 5, bottom: 5, left: 5});

    var map_color_scheme;

    var savedTheme = window.localStorage.theme;
    if (savedTheme == 'dark' || savedTheme == 'light') {
      map_color_scheme = savedTheme.charAt(0).toUpperCase() + savedTheme.substr(1).toLowerCase();;
    } else {
      map_color_scheme = matchMedia('(prefers-color-scheme: dark)').matches ? "Dark" : "Light";
    }

    if (mapkit.maps.length === 0) {
      var map = new mapkit.Map("map", {center: center});
    } else {
      var map = mapkit.maps[0];
      map.setCenterAnimated(center, true);
    }

    map.padding = padding;
    map.showsMapTypeControl = false;

    var calloutDelegate = {
      calloutElementForAnnotation: function(annotation) {
        var popup = document.createElement('div');
        popup.className = 'office-popup search-marker-popup ' + annotation.data.id;

        // Create image link
        var markerImage = document.createElement('img');
        markerImage.src = annotation.data.image;
        markerImage.className = 'logo';
        var link = document.createElement('a');
        link.className = 'search-marker-link';
        link.appendChild(markerImage);

        // Create title
        var name = document.createElement('div');
        name.textContent = annotation.data.name;
        name.className = 'search-marker-name listing-name';

        // Create location
        var location = document.createElement('div');
        location.textContent = annotation.data.city_state;
        location.className = 'search-marker-location listing-location';

        // Create caret
        var caret = document.createElement('div');
        caret.className = 'caret';

        popup.appendChild(link);
        popup.appendChild(name);
        popup.appendChild(location);
        popup.appendChild(caret);

        return popup;
      }
    };


    if (this.hasMarkerValue) {
      this.markerValue.forEach((marker) => {

        if (marker['geocode'] != null) {
          var coord = new mapkit.Coordinate(parseFloat(marker["geocode"]["lat"]), parseFloat(marker["geocode"]["lng"]));
          var memberMarker = new mapkit.MarkerAnnotation(coord, {

            color: 'rgb(74, 82, 90)',
            callout: calloutDelegate,
            glyphImage: {
              1: '/images/doorian-gray.png',
              2: '/images/doorian-gray@2x.png',
              3: '/images/doorian-gray@3x.png'
            },
            data: {
              name: marker.name,
              url: marker.url,
              id: marker.id,
              image: marker.image,
              city_state: marker.city_state
            }
          });
          map.addAnnotation(memberMarker);

        }

      });
    }

    this.tidyUp();

  }

  tidyUp() {
    document.addEventListener('turbo:before-cache', function() {
      if(mapkit.maps.length > 0) {
        mapkit.maps[0].destroy();
      }
    });
  }

  goToRegion(event) {
    event.preventDefault();

    var geo = event.target.getAttribute('data-position');
    var regionCoords = event.target.getAttribute('data-region');
    if (regionCoords) {
      var coords = regionCoords.split(',');
      var boundingRegion = new mapkit.BoundingRegion(parseFloat(coords[2]), parseFloat(coords[3]), parseFloat(coords[0]), parseFloat(coords[1]));
      var region = boundingRegion.toCoordinateRegion();

      if (mapkit.maps.length === 0) {
        var map = new mapkit.Map("map", {region: region});
      } else {
        var map = mapkit.maps[0];
        map.setRegionAnimated (region, true);
      }

    } else if (geo) {
  		var pos = geo.split('|')[0];
  		var zoom = geo.split('|')[1];
      var loc = pos.split(',');
      var locCoordinate = new mapkit.Coordinate(parseFloat(loc[0]), parseFloat(loc[1]));

      if (mapkit.maps.length === 0) {
        var map = new mapkit.Map("map", {center: locCoordinate});
      } else {
        var map = mapkit.maps[0];
        map.setCenterAnimated(locCoordinate, true);
      }

    }

  }

}
