'use strict';

import { Controller } from '@hotwired/stimulus'

var data, searchURL, searchType;

export default class extends Controller {
  static targets = ['keywordInput', 'typeSelect']

  initialize() {
    this.getData();
  }

  getSearchType(url) {
    return 'residential';
  }

  setSearchData() {
    closeSuggestions('#q', '#predictive-search');
    searchURL = this.typeSelectTarget.value
    searchType = this.getSearchType(searchURL);
  }

  getData() {
    searchURL = document.getElementById('home-search') ? jQuery('select').selectpicker('val') : window.location.pathname;
    searchType = this.getSearchType(searchURL);
    this.getLocations(searchType, function(data) {
      data = data;
    });
  }

  getLocations(type, callback) {
    var base_url = 'https://s3.amazonaws.com/luxre.com-data/p/predictive_search/';
    jQuery.getJSON(base_url + type + '.json', callback);
  }

  listenForKeypresses(e) {
    if (data) {
      suggestionEvents('#predictive-search', '#q', data, searchURL);
    } else {
      this.getLocations(searchType, function(data) {
        suggestionEvents('#predictive-search', '#q', data, searchURL, e);
      });
    }
  }

  homeSubmit(e) {
    e.preventDefault();
    var q = document.querySelector('input').value.replace(/[^a-zA-Z-'\d\s:]/g, '');
    searchURL += '?q=' + q.replace(/ /g, '+');

    var placeURL = jQuery("#predictive-search ul li.moveLi.selectedLi a").first().attr("href") ? jQuery("#predictive-search ul li.moveLi.selectedLi a").first().attr("href") : jQuery("#predictive-search ul li.moveLi:not(.keyword) a").first().attr("href");

    // If predictive places are present, use the first place
    if ( placeURL ) {
      searchURL = placeURL
    }
    window.location.href = searchURL;
  }

  reset() {
    closeSuggestions('#q', '#predictive-search')
  }
}
function suggestionEvents(suggestionsObj, input, optionList, searchUrl, e) {

  e.preventDefault();
  var val = document.querySelector(input).value;
  if(val.length > 0) {
    // Do not search if any of these keys are pressed:
    // Esc / Down / Up / Enter / Command(left and right) / Command(Firefox) / Tab keys
    if(e.keyCode !== 27 && e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13 && e.keyCode !== 91 && e.keyCode !== 93 && e.keyCode !== 224 && e.keyCode !== 9) {
      // jQuery(input).removeData();
      displaySuggestions(suggestionsObj, input, optionList, searchUrl);
    } else if (e.keyCode === 27) { // esc key
      closeSuggestions(input, suggestionsObj);
    } else if (e.keyCode === 38 || e.keyCode === 40) { // Down/Up arrow keys
      e.preventDefault();
      e.keyCode === 38 ? previousSuggestion(suggestionsObj, input) : nextSuggestion(suggestionsObj, input);
    }
  } else {
    closeSuggestions(input, suggestionsObj);
  }
}

function getSuggestions(q, optionList, searchUrl) {
  this.optionList = optionList;
  this.q = q;//.replace(/[^a-zA-Z-'\d\s:]/g, '');
  this.searchUrl = searchUrl;
}

getSuggestions.prototype = {

  // Compare input to supplied list
  beginsWith: function(location) {
    if(typeof(location) !== 'undefined') {
      //location = location.replace(/[^a-zA-Z-'\d\s:]/g, '');
      return location.toLowerCase().substr(0, this.q.length) === this.q.toLowerCase();
    }
    return false;
  },

  matchesAltName: function(alternates) {
    var names = alternates.toLowerCase();
    var query = this.q.toLowerCase();
    if(names.includes(query)) {
      var alts = names.split(', ');
      for(var i = 0; i < alts.length; i++) {
        if((alts[i].substring(0, query.length) == query)) { // || alts[i].includes(' ' + query)) {
          return true;
        }
      }
    }
    return false;
  },

  // Create suggestion array
  placeSuggestions: function() {
    var places = [];
    var altPlaces = [];
    var that = this;

    jQuery.each(this.optionList.places, function() {
      if(this.state && typeof(I18n.t("iso3166." + this.state)) !== 'undefined') {

        switch(true) {
          case that.beginsWith(this.city + ' ' + this.state.substr(3, this.state.length) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + this.state.substr(3, this.state.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + I18n.t("iso3166." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("iso3166." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + I18n.t("us_states." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("us_states." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
            var city = {
              city: this.city,
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            places.push(city);
            break;
          case that.matchesAltName(this.names):
            var city = {
              city: this.city,
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            altPlaces.push(city);
            break;
          case that.beginsWith(this.state.substr(3, this.state.length) +  ' ' + this.country):
          case that.beginsWith(this.state.substr(3, this.state.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(I18n.t("iso3166." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.state_plain):
          case that.beginsWith(I18n.t("iso3166." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(I18n.t("us_states." + this.state) +  ' ' + this.country):
          case that.beginsWith(I18n.t("us_states." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.state_names && that.matchesAltName(this.state_names):
            var state = {
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            if (I18n.t("us_states." + this.state)) {
              state.fullState = I18n.t("us_states." + this.state);
            } else if (I18n.t("iso3166." + this.state)) {
              state.fullState = I18n.t("iso3166." + this.state);
            }

            places.push(state);
            break;
          case this.region && that.beginsWith(this.region.substr(3, this.region.length) +  ' ' + this.country):
          case this.region && that.beginsWith(this.region.substr(3, this.region.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.region && that.beginsWith(I18n.t("iso3166." + this.region) +  ' ' + this.country):
          case this.region && that.beginsWith(I18n.t("iso3166." + this.region) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.region_names && that.matchesAltName(this.region_names):
            var state = {
              state: this.region,
              stateI18n: I18n.t("iso3166." + this.region),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            if (I18n.t("iso3166." + this.region)) {
              state.fullState = I18n.t("iso3166." + this.region);
            }

            places.push(state);
            break;

          case that.beginsWith(this.country):
          case that.beginsWith(I18n.t("country_codes." + this.country)):
            var country = {
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(country);
            break;
        }
      } else {
        switch(true) {
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
          case that.matchesAltName(this.names):
            var city = {
              city: this.city,
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(city);
            break;
          case that.beginsWith(this.country):
          case that.beginsWith(I18n.t("country_codes." + this.country)):
            var country = {
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(country);
            break;
        }
      }
    });

    Array.prototype.push.apply(places, altPlaces);
    return places;
  },

  keywordSuggestion: function() {
    var excapeQuotes = this.q.replace(/[']/g, "%27");
    var keywordSearchItem = "<li class='category-heading moveLi keyword'><a class='move' data-turbo-frame='_top' href='" + this.searchUrl + "?q=" + excapeQuotes + this.glsSettingParamString() + "'><i class='fas fa-search'></i>\""  + this.q + "\" Keyword Search</a></li>";
    return keywordSearchItem;
  },

  // Convert suggestion list to html
  domlist: function() {
    var that = this;
    var lis = [];
    var url = this.searchUrl;
    var that = this;
    var places = [];

    lis.push(this.keywordSuggestion());

    [].forEach.call(this.placeSuggestions(), function(place, index, arr) {
      var href = [];
      var text = '';
      var showStatesIn = ['US','CA','AU'];
      if(typeof(place.city) !== 'undefined') {
        href.push('city=' + place.city.replace(/[`~!@#$%^&*_|=?;:",<>\{\}\[\]]/g, '').replace(/'/g, '&#39;').replace(/ /g, '+'));
        text += place.city.titleCase() + ', ';
      }
      if(typeof(place.state) !== 'undefined' && (typeof(place.city) === 'undefined' || showStatesIn.includes(place.country))) {
        href.push('area=' + place.state);
        if(typeof(place.city) === 'undefined' && typeof(place.fullState) !== 'undefined') {
          text += place.fullState + ', ';
        } else {
          text += place.state.substr(3, place.state.length) + ', ';
        }
      } else if(typeof(place.country) !== 'undefined') {
        href.push('area=' + place.country);
      }
      if(typeof(place.country) !== 'undefined') {
        text += place.countryI18n;
      }
      var li = "<li class='moveLi'><a class='move' data-turbo-frame='_top' href='" + url + '?' + href.join('&') + that.glsSettingParamString() + "'>" + text + "</a></li>";
      places.push(li);
    });

    if (places.length > 0) {
      var placeHeader = "<li class='category-heading'><i class='far fa-map-marker'></i>Search in suggested places</li>";
      lis.push(placeHeader);
      places = jQuery.unique(places);
      places = places.slice(0,5);
      lis = lis.concat(places);
    }

    return lis;
  },

  // Get unique suggestions
  unique: function() {
    var unique = this.domlist().filter(function(itm, i, a) {
      return i == a.indexOf(itm);
    });
    return unique;
  },
  
  glsSettingParamString: function() {
    var glsSettings = ['theme', 'background_color', 'parent_location'];
    
    var str = window.location.search;
    var params = {};
    var paramsStr = "";
    
    str.replace(
      new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
      function( $0, $1, $2, $3 ){
        params[ $1 ] = $3;
      }
    );
    
    for (var param in params) {
      if (glsSettings.includes(param)) {
        paramsStr += '&' + param + '=' + params[param];
      }
    }
    
    return paramsStr;
  }
}

function displaySuggestions(suggestionsObj, input, optionList, searchUrl) {
  var qInput = document.querySelector(input);
  var searyQuery = qInput.value.replace(/,/g, '');
  var results = new getSuggestions(searyQuery, optionList, searchUrl);
  var unique = results.unique();

  var formattedResults =[];
  var resultsCount = 10;
  // window.innerWidth < 800
  if(jQuery(window).width() < 800) {
    resultsCount = 7;
  }

  if(unique.length > 0) {
    formattedResults.push(unique);
  }

  // // Highlight default result
  if (formattedResults[0][2]) {
    formattedResults[0][2] = formattedResults[0][2].replace('moveLi', 'moveLi initial');
  }

  if(!formattedResults[0]) {
    closeSuggestions(input, suggestionsObj)
  } else if(formattedResults[0].length > resultsCount) {
    formattedResults = formattedResults[0].slice(0,resultsCount).join('');
  } else {
    formattedResults = formattedResults[0].join('');
  }
  document.querySelector(suggestionsObj +' ul').innerHTML = formattedResults;
}

function nextSuggestion(suggestionsObj, input) {
  var $selectable = jQuery(suggestionsObj).find('.move');
  var count = $selectable.length;
  var index = 0;

  clearInitialHighlight();
  for(var i = 0; i < count; i++) {
    if($selectable[i].getAttribute('aria-selected') === 'true') {
      $selectable[i].setAttribute('aria-selected', 'false');
      jQuery($selectable[i]).removeClass('selected').parent('li').removeClass('selectedLi');
      index = i + 1;
    }
  }

  if(index > count) {
    index = 0;
  }

  goToSuggestion(index, input);
}

// Get index for previous object with class 'move'
function previousSuggestion(suggestionsObj, input) {
  var $selectable = jQuery(suggestionsObj).find('.move');
  var count = $selectable.length;
  var index = count - 1;

  clearInitialHighlight();
  for(var i = 0; i < count; i++) {
    if($selectable[i].getAttribute('aria-selected') === 'true') {
      $selectable[i].setAttribute('aria-selected', 'false');
      jQuery($selectable[i]).removeClass('selected').parent('li').removeClass('selectedLi');
      index = i - 1;
    }
  }

  if(index === -1) {
    index = count;
  }

  goToSuggestion(index, input);
}

// Select next/previous object with class 'move'
function goToSuggestion(index, input) {
  var selectable = jQuery('.keyword-group').find('.move');
  var count = selectable.length;

  jQuery(input).data('url', '');

  if(selectable[index]) {
    selectable[index].setAttribute('aria-selected', 'true');
    jQuery(selectable[index]).addClass('selected').parent('li').addClass('selectedLi');
    jQuery(input).val(jQuery(selectable[index]).text());
    jQuery(input).data('url', jQuery(selectable[index]).prop('href'));
  }
}

// Unrender suggestions
function closeSuggestions(input, suggestionsObj) {
  jQuery(input).removeData();
  document.querySelector(suggestionsObj +' ul').innerHTML = '';
}

function clearInitialHighlight() {
  jQuery('#predictive-search li.moveLi.initial').removeClass('initial');
}
