import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  static targets = [ 'container' ]
  
  static values = {
    state: String
  }
  
  open(element) {
    
    var totalHeight = 0;
    let parent = element.parentElement;
    let ps = parent.querySelectorAll('p');
    let lastParagraph = [].slice.call(ps).pop();
    
    lastParagraph.style.paddingBottom = '50px';
    
    [].forEach.call(ps, function(paragraph, index) {
      totalHeight += paragraph.offsetHeight;
    });
    
    totalHeight += 100;
    parent.style.maxHeight = totalHeight + 'px';
    element.innerText = "Show Less";
    this.stateValue = 'open';
    
  }
  
  close(element) {
    let parent = element.parentElement;
    parent.style.maxHeight = '310px';
    element.innerText = "Show More";
    this.stateValue = 'closed';
  }
  
  toggle(event) {
    
    let element = event.target;
    let state = this.stateValue;
    
    if (state == 'closed') {
      this.open(element);
    } else {
      this.close(element);
    }
    
  }
  
  displayShowMoreButton(e) {
    let element = this.containerTarget;
    var elHeight = 0;
    let ps = element.querySelectorAll('p');
    
    [].forEach.call(ps, function(paragraph, index) {
      elHeight += paragraph.offsetHeight;
    })
    
    if (elHeight >= 300) {
      element.querySelector('.show-more').style.display = 'block';
    } else {
      element.querySelector('.show-more').style.display = 'none';
    }
  }

}
