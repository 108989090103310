import { Controller } from '@hotwired/stimulus'

export default class extends Controller {


  updatePreferredContactMethod(e) {

    var phoneFields = document.querySelectorAll('.phone input');
    var smsFields = document.querySelectorAll('.contactMethod-sms');
    var emailFields = document.querySelectorAll('.contactMethod-email');

    [].forEach.call(phoneFields, function(field) {

      if (e.target.value === 'text_sms') {
        field.required = true;

        [].forEach.call(smsFields, function(input) {
          input.checked = true;
        });

        [].forEach.call(emailFields, function(input) {
          input.checked = false;
        });

      } else {
        field.required = false;

        [].forEach.call(smsFields, function(input) {
          input.checked = false;
        });

        [].forEach.call(emailFields, function(input) {
          input.checked = true;
        });
      }

    });

  }

  trackInquiry() {
    var etjsElement = document.getElementById('et');
    var analyticsController = this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics');
    var inquiryObj = this.data.get('item');
    analyticsController.trackEvent({
      'name': 'lead',
      'action': 'inquiry',
      'label': inquiryObj
    });
  }

}
